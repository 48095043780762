<template>
	<div class="page">
		<!-- 表单 -->
		<div class="huaxue_register_login_box" :style="'height:'+screenHeight">
			<div class="bgimg">
				<img src="../assets/static/login_bg.png"/>
			</div>
			<div class="contitlebox">
				<div class="titlename fontsize20">
					<div class="text hover">修改密码</div>
					<div class="line hover"></div>
				</div>
				<div class="block10"></div>
				<div class="conbox fontsize14">
					<div class="inputdiv">
						<img class="icon" src="../assets/static/phoneicon.png"/>
						<div class="input"><input v-model="formObj.phone" placeholder="请输入手机号码"/></div>
					</div>
					<div class="inputdiv">
						<img class="icon" src="../assets/static/yzmicon.png"/>
						<div class="input"><input v-model="formObj.captcha" placeholder="请输入验证码"/></div>
						<div class="captcha" @click="getcode" v-if="!ifsetMsg">获取验证码</div>
						<div class="captcha" v-if="ifsetMsg">{{count}}s可重发</div>
					</div>
					<div class="inputdiv">
						<img class="icon" src="../assets/static/passicon.png"/>
						<div class="input"><input v-model="formObj.password" placeholder="请设置新密码"/></div>
					</div>
					<div class="inputdiv">
						<img class="icon" src="../assets/static/cpassicon.png"/>
						<div class="input"><input v-model="formObj.confirm" placeholder="请确认密码"/></div>
					</div>
					<div class="block20"></div>
					<div class="btndiv fontsize16" @click="handleForm">确认修改</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import { showLoading, hideLoading } from '@/common/loading.js';
export default {
	data() {
		return {
			screenHeight:"623px",
			formObj:{
				captcha:"",
				phone:"",
				password:"",
				confirm:"",
				signKey:""
			},
			count: '',
			timer: null,
			ifsetMsg: false,//发送验证码
		};
	},
	mounted: function() {
		//获取，宽度百分百，高度是多少
		if(document.documentElement.clientWidth>1920){
			window.onresize = () => {
			  return (() => {
			    this.screenHeight = (document.documentElement.clientWidth * 623) / 1920 + "px";
			  })();
			};
		}
	},
	methods: {
		//注册
		handleForm() {
			var _this = this;
			var params = this.formObj
			if(!params.phone){
				this.$message.error("请输入手机号")
				return;
			}
			if(!params.captcha){
				this.$message.error("请输入验证码")
				return;
			}
			if(!params.password){
				this.$message.error("请输入密码")
				return;
			}
			if(params.password!=params.confirm){
				this.$message.error("两次密码输入不相同！")
				return;
			}
			showLoading();
			this.$http.post('frontLogin/userPassword', params).then(function(res) {
				hideLoading();
				//数据处理
				if (res.code == 200) {
					_this.$alert('修改密码成功，点击跳转登录页面', '提示', {
					    confirmButtonText: '确定',
					    callback: action => {
					      _this.$util.routerPath('/login')
					    }
					});
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取验证码
		getcode(){
			var _this = this
			if(this.ifsetMsg){
				console.log("不可重复发送验证码~")
				return false;
			}
			var params = this.formObj
			var re_phone = /^1(3|4|5|6|7|8|9)\d{9}$/;
			if(!re_phone.test(params.phone)){
				this.$message.error("请输入正确的手机号码")
				return;
			}
			showLoading();
			this.$http.post('frontLogin/captcha', params).then(function(res) {
				hideLoading();
				//数据处理
				if (res.code == 200) {
					_this.$message.success("发送成功~")
					_this.formObj.signKey = res.data
					//启动验证码倒计时
					_this.counttime()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//验证码倒计时
		counttime(){
			var _this = this
			this.ifsetMsg = true
			const TIME_COUNT = 60;
			if (this.ifsetMsg) {
				this.count = TIME_COUNT;
				this.timer = setInterval(() => {
					if (_this.count > 0 && _this.count <= TIME_COUNT) {
						_this.count--;
					} else {
						clearInterval(_this.timer);
						_this.ifsetMsg = false
						_this.timer = null;
						_this.count = '';
					}
			    },1000);
			}
		},
		
	}
};
</script>
<style lang="scss"></style>
